<template>
  <v-container fluid tag="section" aria-labelledby="title">
    <v-row align="center">
      <v-col cols="12">
        <v-card class="elevation-0 rounded-md v-card--variant-elevated">
          <v-card-item>
            <div class="v-card-item__content">
              <div class="d-sm-flex align-center justify-space-between">
                <v-card-title class="text-md-h6 text-body-1 text-wrap font-weight-bold">Выберите услугу</v-card-title>
              </div>
            </div>
          </v-card-item>
          <v-divider></v-divider>
          <v-card-text class="elevation-0 rounded-md v-card--variant-elevated">
            <v-row align="center" >
              <v-col cols="12" md="3">
                <v-card class="mx-auto elevation-2 rounded-md v-card--variant-elevated" >
                  <v-img class="align-end text-white" height="200"
                  :src="require('../assets/600.jpg')" cover>
                  </v-img>
                  <v-card-title>ВМП</v-card-title>
                  <v-card-text>
                    <div>Высокотехнологичная медицинская помощь</div>
                  </v-card-text>

                  <v-card-actions class="pl-4 pr-4">
                    <v-btn to="/vmp" color="white" text="Выбрать" class="v-btn--flat bg-brown-lighten-1 v-btn--density-default v-btn--size-medium v-btn--variant-elevated text-none"></v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>

              <v-col cols="12" md="3">
                <v-card class="mx-auto elevation-2 rounded-md v-card--variant-elevated">
                  <v-img class="align-end text-white" height="200"
                  :src="require('../assets/5f3a6000061df004266427.jpg')" cover>
                  </v-img>
                  <v-card-title>СКЛ</v-card-title>
                  <v-card-text>
                    <div>Санаторно-курортное лечение</div>
                  </v-card-text>

                  <v-card-actions class="pl-4 pr-4">
                    <v-btn to="/skl" color="white" text="Выбрать" class="v-btn--flat bg-brown-lighten-1 v-btn--density-default v-btn--size-medium v-btn--variant-elevated text-none"></v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserService from "../services/user.service";


export default {
  name: "HomePage",
  data() {
    return {
      content: "",
      snilsNumber: "",
      date: "",
      status: "",
      error: ""
    };
  },
  components: {

  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user
    }
  },
  methods: {
    checkUser() {
      const data = {
        snils: this.snilsNumber,
        date: this.date
      }
      UserService.getVMPContent(data).then(
        (ctx) => {
          this.content = ctx;
          if (ctx.status) {
            this.status = true
            this.error = ""
          } else {
            this.status = false,
              this.error = "Данные не найдены"
          }

        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
  beforeMount() {
    if (this.currentUser && this.currentUser.roles != "Guest") {
      this.$router.push('/search-user');
    }
  },
  created() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      this.snilsNumber = user.id
      this.date = user.date

      this.checkUser()
    }
    else {
      this.$router.push('/login');
    }
  },
};
</script>