import { createApp } from "vue";
import App from './App.vue'
import router from "./router"
import store from "./store"
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css';

const vuetify = createVuetify({
  components,
  directives,
})
import './assets/css/style.css'
import { FontAwesomeIcon } from './plugins/font-awesome'
import vue3ExcelJson from 'vue3-excel-json';
import 'vue3-excel-json/dist/style.css';
import PrimeVue from 'primevue/config';
import CKEditor from '@ckeditor/ckeditor5-vue';
import VueSocketIO from 'vue-3-socket.io'
import SocketIO from 'socket.io-client'


createApp(App)
    .use(store)
    .use(router)
    .use(vuetify)
    .use(vue3ExcelJson)
    .use(PrimeVue)
    .use(CKEditor)
    .use(new VueSocketIO({
        debug: false,
        connection: SocketIO('https://status-server.dz72.ru', {
            withCredentials: false,
            autoConnect: true
        }),
        vuex: {
            store,
            actionPrefix: "SOCKET_",
            mutationPrefix: "SOCKET_"
        }
    })
    )
    .component("font-awesome-icon", FontAwesomeIcon)
    .mount('#app')
