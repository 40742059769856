import UserService from "../services/user.service";

const userData = JSON.parse(localStorage.getItem('user'));

export const user = {
    namespaced: true,
    state() {
        return {
            status: [],
            updateChat: null
        }
    },
    actions: {
        async CheckedDataSession(ctx) {
            await UserService.getUserCheck(userData).then(
                (response) => {
                    const userData = response
                    ctx.commit('updateContent', userData)
                },
            )
        },
        checkUpdateChat(ctx) {
            ctx.commit('updateChat')
        }
    },
    mutations: {
        updateContent(state, content) {
            state.status = content
        },
        updateChat(state) {
            state.updateChat++
        }
    },
    getters: {
        getAllContent(state) {
            return state.initialState
        },
        getUpdateChat(state) {
            return state.updateChat
        }
    }
}