import axios from 'axios';
import authHeader from './auth-header';


const API_URL = 'https://status-server.dz72.ru/api/auth/';

class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'signin', {
        username: user.username,
        password: user.password
      })
      .then(async response => {
        if (response.data.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data))
        }
        return response.data;
      });
  }

  loginSNILS(value) {
    return axios
      .post(API_URL + 'vmp/login-user', {
        snils: value.snils,
        date: value.date
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
    sessionStorage.removeItem('user');
    // location.reload()
  }

  register(user) {
    return axios.post(API_URL + 'signup', {
      nickname: user.nickname,
      email: user.email,
      username: user.username,
      roles: user.roles,
      password: user.password
    }, { headers: authHeader() }
    );
  }
}

export default new AuthService();