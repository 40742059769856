import { createWebHistory, createRouter } from "vue-router";
import UserService from "./services/user.service";
import Home from "./components/Home.vue";
import Login from "./components/Login.vue";
import store from "./store"

const user = localStorage.getItem('user')

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/skl",
    name: "SKL",
    component: () => import("./components/HomeSKL.vue"),
  },
  {
    path: "/appointment",
    name: "Appointment",
    component: () => import("./components/HomeAppointment.vue"),
  },
  {
    path: "/vmp",
    name: "VMP",
    component: () => import("./components/HomeVMP.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("./components/Profile.vue"),
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("./components/BoardAdmin.vue"),
    beforeEach: (to, from, next) => {
      UserService.getUserCheck(user).then(
        (response) => {
          if (response != false && response.some(e => e.name == 'admin')) {
            next();
          } else {
            next('/');
          }
        }
      )
      // .catch(() => {
      //   next('/login');
      // });
    }
  },
  {
    path: "/moderator",
    name: "moderator",
    component: () => import("./components/BoardModerator.vue"),
    beforeEach: (to, from, next) => {
      UserService.getUserCheck(user).then(
        (response) => {
          if (response != false && response.some(e => (e.name == 'moderator' || e.name == 'admin'))) {
            next();
          } else {
            next('/');
          }
        }
      ).catch(() => {
        next('/login');
      });
    }
  },
  {
    path: "/upload-skl",
    name: "UploadSKL",
    component: () => import("./components/UploadSKL.vue"),
    beforeEach: (to, from, next) => {
      UserService.getUserCheck(user).then(
        (response) => {
          if (response != false && response.some(e => (e.name == 'moderator' || e.name == 'admin'))) {
            next();
          } else {
            next('/');
          }
        }
      ).catch(() => {
        next('/login');
      });
    }
  },
  {
    path: "/upload-vmp",
    name: "UploadVMP",
    component: () => import("./components/UploadVMP.vue"),
    beforeEach: (to, from, next) => {
      UserService.getUserCheck(user).then(
        (response) => {
          if (response != false && response.some(e => (e.name == 'moderator' || e.name == 'admin'))) {
            next();
          } else {
            next('/');
          }
        }
      ).catch(() => {
        next('/login');
      });
    }
  },
  {
    path: "/calendar",
    name: "calendar",
    component: () => import("./components/BoardCalendar.vue"),

    beforeRouteEnter: (to, from, next) => {
      store.dispatch("user/CheckedDataSession", user).then(
        (response) => {
          if (response != false && response.some(e => (e.name == 'moderator' || e.name == 'admin'))) {
            next();
          } else {
            next('/');
          }
        }
      )
    }
  },
  {
    path: "/search-slot",
    name: "BoardSearchSlot",
    component: () => import("./components/BoardSearchSlot.vue"),

    beforeRouteEnter: (to, from, next) => {
      store.dispatch("user/CheckedDataSession", user).then(
        (response) => {
          if (response != false && response.some(e => (e.name == 'moderator' || e.name == 'admin'))) {
            next();
          } else {
            next('/');
          }
        }
      )
    }
  },
  {
    path: "/search-slot/:snils:id",
    name: "BoardViewUser",
    component: () => import("./components/BoardViewUser.vue"),

    beforeRouteEnter: (to, from, next) => {
      store.dispatch("user/CheckedDataSession", user).then(
        (response) => {
          if (response != false && response.some(e => (e.name == 'moderator' || e.name == 'admin'))) {
            next();
          } else {
            next('/');
          }
        }
      )
    }
  },
  {
    path: "/search-user",
    name: "BoardSearchUser",
    component: () => import("./components/BoardSearchUser.vue"),

    beforeRouteEnter: (to, from, next) => {
      store.dispatch("user/CheckedDataSession", user).then(
        (response) => {
          if (response != false && response.some(e => (e.name == 'moderator' || e.name == 'admin'))) {
            next();
          } else {
            next('/');
          }
        }
      )
    }
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;