<template>
    <v-btn stacked>
        <v-badge :color="content.length > 0 ? 'deep-orange-darken-3' : 'transparent'" :content="this.content.length > 0 ? content.length : ''">
            <v-icon>mdi-bell</v-icon>
        </v-badge>


        <v-menu activator="parent"   >
            <v-card class="mx-auto" activator="parent" location-strategy="connected" scroll-strategy="block">
                <v-list lines="two" class="pa-0" max-width="400" max-height="300">
                    <v-list-item v-for="(n, index) in myNotification" :key="index" border>
                        <v-list-item-title v-html="changeDate(n.createdAt) + `</br>` + n.component + `</br>` + n.title"></v-list-item-title>
                        <v-list-item-subtitle v-html="n.text"></v-list-item-subtitle>
                        <template v-slot:append>
                            <v-btn @click="deleteNotification(n.id)" color="deep-orange-darken-3"
                                icon="mdi-trash-can-outline" variant="text"></v-btn>
                        </template>
                    </v-list-item>
                </v-list>
                <v-btn v-if="content.length > 1" @click="clearAll" class="mt-16 text-none rounded-0" color="blue-grey-darken-4" append-icon="mdi-trash-can-outline" density="default" block>Очистить все</v-btn>
            </v-card>
            
        </v-menu>
    </v-btn>





</template>

<script>
import UserService from "../services/user.service";
import moment from "moment";

export default {
    name: 'NotificationView',
    sockets: {
        'notification'() {
            this.getNotification()
        }
    },
    props: ['user'],
    data() {
        return {
            content: []
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        myNotification() {
            return this.content
        },
        badgeColor() {
            let color = this.content.length > 0 ? 'deep-orange-darken-3' : 'transparent'
            return color
        }
    },
    methods: {
        getNotification() {
            let i = this.currentUser.roles.find((role) => role === 'ROLE_MODERATOR')
            let y = i ? 'Moderator' : this.user

            UserService.getNotification({user: y }).then(item => {
                this.content = item.data.sort((a, b) => {
                    const A = a.id
                    const B = b.id

                    if (A > B) {
                        return -1
                    } else if (A < B) {
                        return 1
                    } else {
                        return 0
                    }
                })
            })
        },
        deleteNotification(item) {
            UserService.deleteNotification({ id: item }).then(data => {
                let i = Number(this.content.findIndex(({ id }) => id == item))
                this.content.splice(i, 1)
                if (data == true) {
                    this.getNotification()
                }
            })
        },
        clearAll() {
            this.content.forEach(async element => {
               await UserService.deleteNotification({ id: element.id })
            });

            this.getNotification()
        },
        changeDate(date) {
            let newDate = moment(date, 'YYYY-MM-DDT00:00Z').format('DD-MMMM-YYYY')
            return newDate
        }
    },
    mounted() {
        this.getNotification()
    }
};
</script>