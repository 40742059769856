import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'https://status-server.dz72.ru/api/';

class UserService {
  getVMPContent(data) {
    return axios.post(API_URL + 'vmp/views',
      data, { headers: authHeader() }
    ).then(response => {
      return response.data;
    });
  }

  getVMPUpdateView(data) {
    return axios.post(API_URL + 'vmp/views-update',
      data, { headers: authHeader() }
    )
  }

  getSKLContent(data) {
    return axios.post(API_URL + 'skl/views',
      data, { headers: authHeader() }
    ).then(response => {
      return response.data;
    });
  }

  getSklUpdateView(data) {
    return axios.post(API_URL + 'skl/views-update',
      data, { headers: authHeader() }
    )
  }

  getSearchAllSlot() {
    return axios.get(API_URL + 'vmp/search-all-slot', { headers: authHeader() });
  }

  getViewUser(snils) {
    return axios.post(API_URL + `user/view/${snils}`,
      {
        snils: snils
      }, { headers: authHeader() }
    ).then(response => {
      return response.data;
    });
  }

  getSearchSlot(data) {
    return axios.post(API_URL + 'vmp/search-slot',
      data, { headers: authHeader() }
    ).then(response => {
      return response.data;
    });
  }

  getRecordSlot(data) {
    return axios.post(API_URL + 'vmp/record-slot',
      data, { headers: authHeader() }
    ).then(response => {
      return response.data;
    });
  }

  getMyRecordSlot(data) {
    return axios.post(API_URL + 'vmp/my-slot',
      data, { headers: authHeader() }
    ).then(response => {
      return response.data;
    });
  }

  getUserBoard() {
    return axios.get(API_URL + 'board/user', { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + 'board/mod', { headers: authHeader() })
  }

  getSklData(data) {
    return axios.post(API_URL + 'skl/update',
      data, { headers: authHeader() }
    ).then(response => {
      return response.data;
    });
  }

  getSklUpdateRow(data) {
    return axios.post(API_URL + 'skl/record-update',
      data, { headers: authHeader() }
    ).then(response => {
      return response.data;
    });
  }

  getVmpData(data) {
    return axios.post(API_URL + 'vmp/update',
      data, { headers: authHeader() }
    ).then(response => {
      return response.data;
    });
  }

  getVmpUpdateRow(data) {
    return axios.post(API_URL + 'vmp/record-update',
      data, { headers: authHeader() }
    ).then(response => {
      return response.data;
    });
  }

  getVmpSlot(data) {
    return axios.post(API_URL + 'vmp/slot',
      data, { headers: authHeader() }
    ).then(response => {
      return response.data;
    });
  }

  getDeleteVmpSlot(data) {
    return axios.post(API_URL + 'vmp/slot-delete', data, { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + 'board/admin', { headers: authHeader() });
  }

  async getUserCheck(data) {
    return await axios.post(API_URL + 'user/check',
      data
    ).then(response => {
      return response.data;
    });
  }

  getUserDelete(user) {
    return axios.post(API_URL + `user/delete/${user}`,
      {
        id: user
      }, { headers: authHeader() }
    ).then(response => {
      return response.data;
    });
  }

  getUserUpdate(user) {
    let data = user
    for (let key in data) {
      if (data[key] == '') {
        delete data[key]
      }
    }
    return axios.post(API_URL + `user/update/${data.id}`,
      {
        id: data.id,
        nickname: data.nickname,
        email: data.email,
        username: data.username,
        password: data.password,
        roles: data.roles
      }, { headers: authHeader() }
    ).then(response => {
      return response.data;
    }).catch(error => {
      return error
    });
  }

  sendMail(data) {
    return axios.post(API_URL + 'mail/send',
      data, { headers: authHeader() }
    ).then(response => {
      return response.data;
    });
  }

  myMessage(data) {
    return axios.post(API_URL + 'message/data',
      data, { headers: authHeader() }
    ).then(response => {
      return response.data;
    });
  }

  sendMessage(data) {
    return axios.post(API_URL + 'message/send',
      data, { headers: authHeader() }
    ).then(response => {
      return response.data;
    });
  }

  getNotification(data) {
    return axios.post(API_URL + 'notification',
      data, { headers: authHeader() }
    )
  }

  createNotification(data) {
    return axios.post(API_URL + 'create-notification',
      data, { headers: authHeader() }
    )
  }

  deleteNotification(data) {
    return axios.post(API_URL + 'delete-notification',
      data, { headers: authHeader() }
    )
  }
}


export default new UserService();